// extracted by mini-css-extract-plugin
export var blogHeadImage = "blog-module--blog-head-image--z6iF8";
export var blogPage = "blog-module--blog-page--pIuZH";
export var blogPageLink = "blog-module--blog-page-link---6a9m";
export var blogpost = "blog-module--blogpost---8Ww+";
export var brandedTitle = "blog-module--branded-title--KADji";
export var cardHover = "blog-module--card-hover--T+U2P";
export var darkBackground = "blog-module--dark-background--YZHI4";
export var marginLeft05rem = "blog-module--margin-left-05rem--cCWDA";
export var marginTop15rem = "blog-module--margin-top-1-5rem--MUz+q";
export var marginTopMinus05rem = "blog-module--margin-top-minus-05rem--AiCR9";
export var onTop = "blog-module--on-top--THUpj";
export var paddingBottom1rem = "blog-module--padding-bottom-1rem--itOt3";
export var paddingTop1rem = "blog-module--padding-top-1rem--ggnXV";
export var posts = "blog-module--posts--OfHwg";
export var underline = "blog-module--underline--vSZLI";